.product-scroller {
    overflow: auto;
    white-space: nowrap;
}

.product-scroller a {
    display: inline-block;
  /* color: green; */
  text-align: center;
  margin: 2px;
  /* padding: 14px; */
  text-decoration: none;
  /* border: black solid 1px; */
}

.product-scroller a:hover {
  /* background-color: #ddd; */
  color: black;
}

.product-scroller img {
    max-width: fit-content;
    height: 200px;
}
#na {
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 2.3%;
  margin-bottom: 1%; 
}
.product-scroller h1 {
    
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 2.3%;
  margin-bottom: 1%; 
}



.BTC {
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 2.3%;
  margin-bottom: 1%; 
}


.spring-banner {
  display: flex;
  /* justify-content:; */
  align-items: center;
  text-align: center;
  /* height: 43vh; */
  /* width: 100% */
  flex-direction: column;
  margin-top: 50px;
  width: auto;
}

.spring-banner h1, h2, h3 {
  text-align: center;
  
} 

.spring-banner h1 {
  font-size: 16px;
  /* color: white; */
  font-family: 'Roboto', sans-serif;
  margin-bottom: 5px;
}



.spring-banner h3 {
  font-size: 16px;
  /* color: white; */
  font-family: 'Roboto', sans-serif;
  width: 33%;
  line-height: 150%;
  
}

.always-iconic {
  margin: 2.2%;
}

.always-iconic h1 {
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 0.3%;
  margin-bottom: 1%; 
}

.always-iconic h2 {
  font-family: 'Roboto', sans-serif;
  text-align: left;
  margin-top: 4%;

}



.alwaysiconic-images {
  /* display items in a row */
  display: flex;
  flex-direction: row;
  
}

.alwaysiconic-images img {
  /* make the images responsive */
  width: 100%;
  height: auto;
  /* add some spacing between the images */
}

.alwaysiconic-jordan {
  margin: 0.3%;
}

.alwaysiconic-airmax {
  margin: 0.3%;
}

.alwaysiconic-cortez {
  margin: 0.3%;
}



.spring-banner h2 {
  font-size: 70px;
  /* color: white; */
  font-family: 'Nike', sans-serif;
  width: 50%;
  letter-spacing: -3px;
  font-weight: bold;
  font-stretch: expanded;
  line-height: 86%;
}

/* change spring-banner button to have styling similar to nike */
.spring-banner button {
  background-color: #111;
  color: white;
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-top: 20px;
  cursor: pointer;
  border-radius: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.08em;
  /* width: 50%; */
}

.spring-banner button:hover {
  background-color: #757575;
}

.hap-now {
  margin: 2.2%;
}

.hap-now h1{
  font-size: 24px;
  font-weight: 500;
  line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 0.3%;
  margin-bottom: 1%; 
}

.hap-now img {
  width: 99%;
  height: auto;
}

.hap-now-text {
  position: absolute;
  margin-top: 4%;
  margin-left: 2.3%;
  width: 100%;
}

.hap-now h2 {
  /* position: absolute; */
  font-family: 'Nike', sans-serif;
  text-align: left;
  /* margin-top: 4%; */
  color: white;
  font-size: 70px;
  letter-spacing: -3px;
}

.hap-now h3 {
  /* position: absolute; */
  font-family: 'Roboto', sans-serif;
  text-align: left;
  /* margin-top: 4%; */
  color: white;
}

.hap-now button {
  /* position: absolute; */
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.08em;
  /* width: 50%; */
  margin-top: 3%;
}

.hap-now button:hover {
  background-color: #9e9d9d;
}

#teeup {
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 2.3%;
  margin-bottom: 1%; 
}
/* Add the following styles */
.first-teeup,
.second-teeup {
  position: relative;
}

.first-teeup-text,
.second-teeup-text {
  position: absolute;
  bottom: 10px;
  left: 10px;
  color: white;
  padding: 10px;
  border-radius: 5px;
  margin: 25px;
}

.double-blocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2.2%;
}


.double-blocks img {
width: 100%;
height: auto;
}

/* Update the following styles */
.teeup-blocks {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 2.2%;
  justify-content: space-between;

}

.teeup-blocks img {
  max-width: 98%;
  height: auto;
  margin: 0.3%;
}

.teeup-blocks h1 {
  font-size: 17px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 0.3%;
  margin-bottom: 1%; 
  width: 100%;
}

.teeup-blocks h2 {
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 0.3%;
  margin-bottom: 1%; 
  width: 100%;
}

.teeup-blocks button {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  /* display: inline-block; */
  font-size: 16px;
  cursor: pointer;
  border-radius: 40px;
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  letter-spacing: 0.08em;
  /* width: 50%; */
  margin-top: 3%;
}

.teeup-blocks button:hover {
  background-color: #9e9d9d;
}

.pop-now h1 {
    
  font-size: 24px;
  font-weight: 500;
    line-height: 28px;
  font-family: 'Roboto', sans-serif;
  margin-left: 2.3%;
  margin-bottom: 1%; 
}

.pop-now img {
  width: 99%;
  height: auto;
}

@media (max-width: 767px) {
  /* Add your mobile-specific CSS styles here */

  .spring-banner h2 {
    font-size: 40px;
    width: 80%;
  }

  .spring-banner h3 {
    font-size: 14px;
    width: 90%;
  }

  .always-iconic h1,
  .hap-now h1 {
    font-size: 20px;
  }

  .alwaysiconic-images {
    /* flex-direction: column; */
  }

  .always-iconic h2 {
    margin-top: 2%;
  }

  .alwaysiconic-images img {
    margin-bottom: 15px;
  }

  .hap-now img {
    width: 100%;
  }

  .hap-now-text {
    /* margin-top: 10%; */
    
  }

  .hap-now-text h2{
    font-size: 30px !important;
  }
  
  .hap-now-text h3 {
    display: none;
  }

  .hap-now button {
    margin-top: -20%;
    /* margin-bottom: 20px !important; */
    font-size: 13px;
    padding: 5px 10px;
  }

  .hap-now-text {
    /* height: 100px; */
    /* padding: none; */
    margin-top: 2.3% !important;
  }
  .first-teeup-text{
      
      /* margin-top: 30px; */
      padding: 0px;
      margin: 0px;
      
  }

  .first-teeup-text button{
    font-size: 14px;
    padding: 5px 10px;
    margin-left: 110px;;
  }

  .second-teeup-text{
    
    /* margin-top: 30px; */
    padding: 0px;
    margin: 0px;
    margin-left: 110px;
  }

  .second-teeup-text button{
    font-size: 14px;
    padding: 5px 10px;
  }

  .first-teeup-text h1 {
    display: none;
  }

  .second-teeup-text h1 {
    display: none;
  }

  .first-teeup-text h2 {
  
    display: none;
  }

  .second-teeup-text h2 {
    display: none;
  }



}


