#bag-header {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    margin-bottom: 24px;
  }

  .cart-index {
    display: flex;
    flex-direction: column;
    flex-grow: 2;
    min-height: 300px;
    padding: 20px;
    box-sizing: border-box;
    width: calc(100% * 2 / 3);
  }
  

  .cart-total {
    display: block;
    width:  330px;
    height: fit-content;
    /* border: 1px solid #CACACA; */
    border-radius: 5px;
    /* margin-right: 200px; */
    padding: 20px;
  }

.cart-total h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 22px;
    margin-bottom: 24px;
}

.cart-total h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin-bottom: 5px;
}

.cart-total h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.cart-total h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.cart-total h5 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
}

.cart-total h6 {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    margin-bottom: 20px;
}


#checkout-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    font-family: "Helvetica Neue";
    border-radius: 5px;
    padding-top: 20px;
  }
  
  #checkout-item {
    width: 100%;
  }

  #breakline-cart {
    height: 1px;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #CACACA;
  }

  .checkout-button {
    background-color: #111;
    color: #fff;
    border-radius: 50px;
    justify-content: center;
    padding: 20px;
    height: 65px;
    width: 100%;
    /* margin-left: 10px; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    cursor: pointer;
  }

  .checkout-button:hover {
    background-color: #404040;
    
  }

  .cart-container {
    display: flex;
    padding: 20px;
    margin-left: 13vw;
    margin-right: 13vw;
    min-height: 70vh;
    /* height: 7vh; */
  }
  

.promo-code-toggle:hover {
  cursor: pointer;
}

.promo-code {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.3s ease-in-out;
}

.promo-code.show {
  max-height: 100px;
}

.label-value-container {
  display: flex;
  justify-content: space-between;
  height: 15px;
}

.promo-code-toggle-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.promo-code-toggle-container:hover{
  cursor: pointer;
}

 i:hover {
  color: #756f6f;
}

#apply {
  background-color: #ffffff;
    color: #757575;
    border-radius: 17px;
    /* justify-content: center; */
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    /* max-width: 70px; */
    height: fit-content;
    /* margin-left: 10px; */
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    cursor: pointer;
    border : 1px solid #CCCCCC;
    text-align: center;
}

#apply:hover {
  border: #CCCCCC solid 1px;
  background-color: #f2f2f2;
}

#promo-code-input{
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  /* height: 35px; */
  /* width: 70%; */
  padding: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  color: #a4a4a4;
}

#promo-code-input:focus {
  outline: none;
  border:  #a4a4a4 solid 1px;
}

#promo-code-input::value {
  color: black;
}

.input-and-button {
  display: flex;
  align-items: center;
  flex-direction: row;
  height: 55px;
  margin: 5px;
}

#bold {
  font-weight: bold !important;
  font-size: 15px;
}

#display-price{
  color: #128A09;
}

@media (max-width: 768px) {
  .cart-container {
    flex-direction: column;
    margin-left: 5vw;
    margin-right: 5vw;
  }

  .cart-index {
    width: 100%;
    margin-bottom: 20px;
  }

  .cart-total {
    width: 100%;
  }
}
