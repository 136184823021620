

.cart-item img {
    height: 200px;
    width: 200px;
}

.left-side-cart img {
    height: 150px;
    max-width: 150px;
}

.cart-item-container {
    display: flex;
    flex-direction: row;
    /* width: fit-content; */
    max-width: 100%;
}


#cart-label{
    font-family: 'Roboto', sans-serif;
    background: transparent;
}

.cart-label select {
    font-family: 'Roboto', sans-serif;
    background: transparent;
    border: none;
}

.cart-label select option {
    font-family: 'Roboto', sans-serif;
    background: transparent;
}

.cart-item-info {
    padding-left: 25px;
    padding-right: 10px;
    padding-bottom: 10px;
    width: 100%;
}

.right-side-cart{
    width: 100%;
}

.name {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    color: black;
}

.price {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    color: black;
    padding-right: 15px;
}

#cat-name {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    color: rgb(117, 117, 117);
}

#prod-color {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    color: rgb(117, 117, 117);
}

.cart-item-info h2 {
    color: rgb(117, 117, 117);
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}

.cart-item-info h3 {
    color: rgb(117, 117, 117);
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}

.cart-item-info label {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.cart-item-info input {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

.cart-item-info button {
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
}

#breakline {
    height: 1px;
    margin: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    background-color: #CACACA;
  }

  #trashcan {
    max-height: 100%;
    max-width: 35px;
    /* margin-right: 20px; */
    cursor: pointer;
}

.loader {
    border: 6px solid #f3f3f3;
    border-top: 6px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }


  .name-and-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
  }

  .size-and-quantity {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    color: rgb(117, 117, 117);
    align-items: center;
  }

  .cart-label-select {
    color: rgb(117, 117, 117);
  }

  .quantity input {
    color: rgb(117, 117, 117);
  }

  .under-cart-item-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100px;
  }

  .under-cart-item-left h1 {
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
    text-decoration: none !important;
    color: black;
    line-height: normal
  }

  .UC-ship h1{
    line-height: 1.75;
  }

  .UC-pick h1{
    line-height: 1.75;
  }

  #trash-icon {
 font-size: 20px;
 padding-top: 10px;
  }

  #trash-icon:hover {
    cursor: pointer;
  }


 