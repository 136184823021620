/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


    .modal-show {
        visibility: visible;
    }

    .modal-hide {
        visibility: hidden;
    }

    .nav-bar {
        background-color: #F5F5F5;
        height: 25px;
    }

    #seperator-bar{
      margin-left: 2px;
      margin-right: 2px;
    }

   



  

    @font-face {
      font-family: 'Nike';
      src: url('../../app/assets/fonts/Futura\ Condensed\ Extra\ Bold.otf') format('opentype');
    }

   
    #root {
      overflow-x: hidden;
    }

    body {
      overflow-x: hidden;
    }

    html {
      /* overflow-x: hidden; */
    }


    .whole-app {

      /* max-width: 100%; */

      margin: 0;
      padding: 0;
      box-sizing: border-box;
      /* border: red solid 1px; */
    }
    
    .cart-app {
      display: flex;
      /* height: 95vh;   */
      /* height: 100vh !important; */
      flex-direction: column;
      justify-content: space-between;
    }