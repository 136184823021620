.search-results {
  display: flex;
  /* margin-top: 20px; */
  margin-left: 20px;
  margin-right: 20px;
  justify-content: space-between;
  overflow-y: hidden;
  height: 70vh;
}

/* remove the scrollbar from the .search-results */

.search-results::-webkit-scrollbar {
  display: none;
}

.search-results-controls {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 200px; 
  overflow-y: auto; 
  max-height: 70vh ; 
  overflow-x: hidden;
  padding-left: 20px;
  padding-right: 10px;
}

.search-results-controls h1 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 15px;
}

.category-options label,
.product-type-options label {
  display: block;
  margin-bottom: 5px;
}

.category-options input[type="radio"],
.product-type-options input[type="radio"] {
  margin-right: 5px;
}
.search-results-items {
  display: grid;
  padding: 5px;
  grid-template-columns: repeat(3, 1fr);
  width: calc(100% - 320px);
  grid-gap: 15px;
  overflow-y: auto; 
  height: 75vh ;
  overflow-x: hidden; 
  padding-right: 10px;
}


.product-type-options p {
  font-family: "Roboto", sans-serif;
}

.search-results h1, label, p, select, option {
  font-family: "Roboto", sans-serif;
}
/* The rest of the CSS remains the same */

.search-results-controls hr {
  border: none;
  border-top: 1px solid lightgray;
  margin: 15px 0;
}

input[type="radio"] {
  appearance: none;
  width: 16px;
  height: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  outline: none;
  transition: all 0.1s ease;
}

input[type="radio"]:checked {
  background-image: url("https://cdn-icons-png.flaticon.com/512/61/61141.png");
  background-size: 14px 14px;
  border: 1px solid #000000;
}

input[type="radio"]:focus {
  border: 1px solid #efefef;
  
}

input[type="radio"]:hover {
  border: 1px solid #efefef;
  cursor: pointer;
}

.collapsible-content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  padding: 5px;
}

.collapsible-content.active {
  max-height: 500px; /* You can adjust this value based on the actual content height */
  opacity: 1;
  height: fit-content;
  padding: 5px;
}


.color-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
}

.color-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  font-size: 11px;
}

.color-circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.color-circle.all {
  border: black solid 1px;
  background-image: linear-gradient(0deg, gray 1px, transparent 1px),
    linear-gradient(90deg, gray 1px, transparent 1px),
    linear-gradient(rgba(255, 255, 255, 1) 1px, transparent 1px),
    linear-gradient(90deg, rgba(255, 255, 255, 1) 1px, transparent 1px);
  background-size: 10px 11px;

}


.color-circle.black {
  background-color: black;
}

.color-circle.blue {
  background-color: blue;
}

.color-circle.brown {
  background-color: brown;
}

.color-circle.green {
  background-color: green;
}

.color-circle.grey {
  background-color: grey;
}

.color-circle.multi-color {
  background-image: linear-gradient(to left, violet, indigo, blue, green, yellow, orange, red);
}

.color-circle.orange {
  background-color: orange;
}

.color-circle.pink {
  background-color: pink;
}

.color-circle.purple {
  background-color: purple;
}

.color-circle.red {
  background-color: red;
}

.color-circle.white {
  background-color: white;
  border: 1px solid #9d9999;
}

.color-circle.yellow {
  background-color: yellow;
}


.sr-wrapper {
  padding-left: 10px; 
  padding-right: 10px;
  padding-top: 10px;
  overflow: hidden !important;
  overflow-y: hidden;
}

.sr-wrapper::-webkit-scrollbar {
  display: none;
}

.hide-main-scrollbar {
  overflow: hidden !important;
}

.search-results-controls::-webkit-scrollbar,
.search-results-items::-webkit-scrollbar {
  width: 8px;
}

.search-results-controls::-webkit-scrollbar-thumb,
.search-results-items::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 4px;
}

.search-results-controls::-webkit-scrollbar-track,
.search-results-items::-webkit-scrollbar-track {
  background: none;
}

.search-results-controls,
.search-results-items {
  scrollbar-color: gray transparent;
  scrollbar-width: thin;
}

.pt-and-collapse{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
}


.icon:hover {
  cursor: pointer;
    color: #9d9999;
}


.sort-options {
  cursor: pointer;
}

.search-results-header {
  height: fit-content;
  padding-bottom: 10px;
}

.search-results-header-left{
  height: fit-content;
  
}

.search-results-header-right{
  height: fit-content;
}

.search-results-header-left h1 {
  font-size: 14px;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  left: 0;
}

.search-results-header-left h2 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  left: 0;
}

.search-for {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  margin-bottom: 14px;
}

/* .whole-search {
  overflow-y: hidden;
  overflow-x: hidden;
} */

.whole-search::-webkit-scrollbar {
  display: none;
}

.color-container {
  padding: 5px;
  border-radius: 5px;
}

.color-container input:focus {
  background-color: #efefef;
}

.color-container:hover {
  cursor: pointer;
  background-color: #efefef;
}

.color-container.selected {
  background-color: #efefef;
}


/* Mobile devices */
@media (max-width: 767px) {
  .search-results {
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    height: auto;
  }
  
  .search-results-controls {
    /* display: none; */
    margin-right: 0;
    width: 100%;
    max-height: none;
    overflow-y: visible;
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 20px;
  }

  .search-results-items {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    height: auto;
    padding-right: 0;
  }

  .toggle-controls-button button{
    
      background-color: #111;
      color: white;
      border: none;
      padding: 10px 20px;
      text-align: center;
      text-decoration: none;
      display: inline-block;
      font-size: 13px;
      /* margin-top: 20px; */
      cursor: pointer;
      border-radius: 40px;
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      letter-spacing: 0.08em;
      /* width: 50%; */
      margin-bottom: 20px;
    }
    
    .toggle-controls-button button:hover {
      /* background-color: #9d9999; */
      /* color: white; */
    }
    .search-results-items {
      grid-template-columns: repeat(2, 1fr);
    }

    .search-results-header {
      /* height: fit-content; */
      /* padding-bottom: 10px; */
      display: flex;
      /* justify-content: space-evenly; */
      padding: 0;
      padding-left: 0;
      padding-right: 0;
      margin-left: 0px;
      margin-right: 0px;
    }

}

