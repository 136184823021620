.slide-show-bar {
    display: flex;
    width: 100%;
    height: 60px;
    background-color: #F5F5F5;
    align-items: center;
    justify-content: center;
  }
  
  .message-container {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: 'Roboto', sans-serif;
  }
  
  
  .join-now-button {
    background: none;
    border: none;
    color: #111;
    cursor: pointer;
    font: inherit;
    outline: none;
    padding: 0;
    text-decoration: underline;
  }
  
  .slide-nav-button {
    background: none;
    border: none;
    color: #111;
    cursor: pointer;
    font-size: 18px;
    font-weight: bold;
    outline: none;
    padding: 0;
  }
  
  .prev-button {
    margin-right: 10px;
    color: #9B9B9B;
  }
  
  .next-button {
    margin-left: 10px;
    color: #9B9B9B;
  }

  .prev-button:hover {
    color: #111;
  }

  .next-button:hover {
        color: #111;
    }

    #promo-code {
        color: #111;
        font-size: 16px;
        font-weight: bold;
        font-family: 'Roboto', sans-serif;
        letter-spacing: 0.08em;
    }
  
    .message-wrapper {
  width: 33vw;
  text-align: center;
  padding: 5%;
  position: relative;
  transition: transform 0.5s;
}

@media (max-width: 767px) {
  .message-container {
    font-size: 14px;
  }

  .slide-nav-button {
    font-size: 16px;
  }

  .prev-button {
    margin-right: 5px;
  }

  .next-button {
    margin-left: 5px;
  }

  .message-wrapper {
    width: 80vw;
  }

  #promo-code {
    font-size: 14px;
  }
}