.second-nav-bar-container{
  padding-left: 15px;
  padding-right: 15px;
}

.second-nav-bar {
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-items: center;
    justify-content: space-between;
    margin: 5px;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 99%;
    height: 40px;
  }

  .right-side-nav-bar {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  #logo {
    max-width: 61px;
    height: 100%;
    margin: 20px;
    margin-right: 12vw ;
}

  .category-buttons {
    display: flex;
    justify-content: center;
    
    /* make buttons as wide as the text inside */
  }

  
  .category-button {
    padding: 11px;
    font-family: 'Roboto', sans-serif;
    background: transparent;
    font-size: 17px;
    /* font-weight: 100; */
    border: none;
    width: fit-content;
    border-bottom: 2px solid transparent; /* Add this line */
  }

  .category-buttons a {
    text-decoration: none;
  }
  .category-button:hover {
    cursor: pointer;
    color: #ffffff;
    color: #111;
    border-bottom: 2px solid #111;
  }


#bag {
  color: black;
  font-size: 25px;
  margin-left: 15px
}


#searchbar {
  height: 40px;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 40px;
  cursor: pointer; /* Change the cursor to a pointer */
  border: none;
}

#searchbar:hover{
  background-color: #E5E5E5;
}

input#searchbar:focus {
  outline: none;
}

#searchbar::placeholder {
  color: #CCCCCC;
  font-size: 15px;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 0.08em;
  padding-left: 5px;
}

.search-and-mag {
  display: flex;
  flex-direction: row;
  background-color: #f5f5f5;
  align-items: center;
  padding: 4px;
  border-radius: 40px;
}


.search-and-mag i {
  margin-right: 10px;
  color: #111;
  padding: 13px;
  text-decoration: none;
  color: #111;
  
}

.search-and-mag i:hover {
  background-color: #E5E5E5;
  border-radius: 40px;
  cursor: pointer;
  color: #111;
}

.search-container {
  position: relative;
  display: inline-block;
  margin-right: 30px;
}

@media (max-width: 926px) {

  #logo {
    max-width: 35px !important;
    height: 15px !important;
    margin: 20px;
    margin-right: 0px;
  }


  .category-button {
    padding: 8px;
    font-size: 14px;
  }

  .second-nav-bar {
    flex-direction: column;
    height: auto;
  }



  .category-buttons button {
   /* overflow-x: auto; */
   /* padding: 20px; */
    /* width: 80%; */
    /* width: 50% !important; */
    padding: 5px;
    border: #E5E5E5 solid 1px;
    border-radius: 5px;
  }

  .category-buttons {
    justify-content: space-around;
  }

  #searchbar {
    width: 80%;
  }

  .search-and-mag {
    margin: 0 auto;
    margin-top: 10px;
  }

  .search-and-mag i {
    margin-right: 5px;
  }

  #bag {
    font-size: 20px;
    margin-left: 10px;
  }
}