
#fav-button {
  background-color: white;
  color: black;
  border: 2px solid #CACACA;
}
.product-show-page {
  /* padding-top: 20vh;
  padding-bottom: 20vh; */
  display: flex;
  justify-content: center;
  margin: 38px;
}

.product-show-page img {
  display: flex;
  float: left;
  margin: 100px;
  max-width: 100%;
  height: 500px;
}

.product-info {
  /* border: red solid 1px; */
  display: flex;
  flex-direction: column;
  margin-left: 100px;
  margin-right: 50px;
  margin-bottom: 50px;
  width: 400px;
  height: fit-content;
  font-family: "Helvetica Neue";
  border-radius: 5px;
  padding: 20px;
}
.product-show-page h1 {
  /* border: red solid 1px; */
  display: flex;
  padding: 10px;
  font-family: 'Roboto', sans-serif;
  font-size: 28px;

}
.product-show-page h2 {
  display: flex;
  /* border: red solid 1px; */
  padding: 10px;
  
  font-family: 'Roboto', sans-serif;
  flex-wrap: wrap;
}

.product-show-page h3 {
  /* border: red solid 1px; */
  display: flex;
  padding: 4px;
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
}
.product-show-page p {
  /* border: red solid 1px; */
  display: flex;
  /* margin: 20px;
  padding: 10px; */
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  margin-left: 20px;
}

.product-show-page h5 {
  /* border: red solid 1px; */
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 26px;

  
}

.size-buttons {
    display: flex;
    flex-wrap: wrap;
    width: 370px;
    height: 220px;
    
    padding: 11px;
  }

  
  .size-buttons button {
    /* display: flex; */
    margin: 2px;
    height: 50px;
    width: 70px;
    border: #dcd8d8 solid 1px;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    cursor: pointer;
    background-color: white;
  }
  .size-buttons p {
    display: flex;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    font-weight: 600;
    margin: 10px;
    height: fit-content;
  }
  .size-buttons button:hover {
    border: #111  solid 1px;
  }
  #fav-emoji {
    /* display: flex; */
    font-size: 20px;
    float: right;
    /* justify-content: center; */
    /* align-items: center; */
  }
  .add-favorite-show-page-buttons button {
    display: flex;
    background-color: #111;
    color: #fff;
    border-radius: 50px;
    justify-content: center;
    padding: 20px;
    height: 65px;
    width: 375px;
    margin-left: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    cursor: pointer;

}


.add-favorite-show-page-buttons button:hover {
  background-color: #757575;
  color: #ffffff;
}

#review-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

#review-button:hover {
  color: #757575;
}

#review-button h5 {
  font-size: 20px;
}

#v {
  font-size: 10px;
  display: flex;
  margin-top: 9px;
  margin-left: 275px;
  font-weight: 999;
}

#breakline {
  height: 1px;
  margin: 5px;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  background-color: #CACACA;
}

.create-review {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: fit-content;
  font-family: "Helvetica Neue";
  border-radius: 5px;
}

.create-review h5 {
  font-size: 20px;
  margin-bottom: 20px;
}

.create-review input {
  margin: 10px;
  height: 30px;
  width: 350px;
  border: #CACACA solid 1px;
  border-radius: 5px;
  padding: 5px;
}

.create-review button {
  
  margin: 10px;
  height: 30px;
  width: 150px;
  border: #CACACA solid 1px;
  border-radius: 5px;
  padding: 5px;
  background-color: #111;
  color: #fff;
  cursor: pointer;
}

.banner {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #111; /* Updated background-color */
  color: white;
  padding: 10px 20px;
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 999;
  font-family: 'Roboto', sans-serif; /* Updated font-family */
}

.loading-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 3px;
  background-color: #606462;
  width: 0;
  transition: width 0.1s linear;
}

#close-banner {
  cursor: pointer;
  color: white;
  background-color: transparent;
  border: none;
  
}

#sale-price {
  color: #848383;
}

@media only screen and (max-width: 768px) {
  .product-show-page {
    flex-direction: column;
    margin: 0;
    padding: 20px;
  }

  .product-show-page img {
    margin: 0;
    height: 300px;
    margin-left: 50px;
  }

  .product-info {
    margin-left: 0;
    margin-right: 0;
    width: 91%;
  }

  .size-buttons {
    width: 100%;
    height: auto;
    padding: 0;
  }

  .add-favorite-show-page-buttons button {
    width: 100%;
    margin-left: 0;
  }

  .create-review {
    width: 100%;
  }

  .create-review input {
  
    width: 250px;
    
  }
}
