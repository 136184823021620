/* ErrorModal.css */
.error-modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .error-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 400px;
    width: 80%;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 24px;
    cursor: pointer;
  }
  
  .ok-button {
    background-color: #333;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
    }
    
    .error-modal h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    }
    
    .error-modal p {
    font-size: 16px;
    line-height: 1.4;
    margin-bottom: 10px;
    }  

    #em-title {
        font-family: 'Roboto', sans-serif !important;
    }

    .em-info{
        display: flex;
        flex-direction: column;
    }

    