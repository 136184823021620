.nav-bar {
	background-color: #F5F5F5;
	height: 36px;
}

.nav-bar img {
    display: flex;
    max-width: 100px;
  }

  .nav-bar button:hover {
	cursor: pointer;
  }
  
  #sign-up {
	display: flex;
	float: right;
	background: transparent;
	border: none;
	margin: 5px;
}

#sign-up p {
	display: flex;
	float: right;
	font-size: 11px;
}

#greeting {
	display: flex;
	float: right;
	font-size: 11px;
	background: transparent;
	border: none;
	margin: 5px;
}