.nike-video-container {
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    max-width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    /* margin: 10px; */
    /* margin-top: none; */
    margin-left: 1.5%;
    margin-right: 1.5%;
    margin-bottom: 1%;
  }
  
  .nike-video-container video {
    width: 100%;
    height: auto;
  }