.search-result-index-item {
  display: block;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: 1px;
  /* border: 1px solid #ddd; */
  background-color: #fff;
  text-align: center;
  font-size: 14px;
  max-width: 500px; /* Set a fixed width for each item */
  height: 100%; /* Set a fixed height for each item */
  margin-bottom: 30px;
}

.search-result-index-item img {
  width: 592px;
  height: 592px;
}
  .search-result-index-item a {
    text-decoration: none;
    color: inherit;
    left: 0;
    height: 100%;
  }
  
  .search-result-index-item img {
    width: 100%;
    height: auto;
    max-height: 450px;
    margin-bottom: 10px;
  }
  
  
  .search-result-index-item h3 {
    font-size: 16px;
    overflow: hidden; 
    text-overflow: ellipsis; 
    white-space: nowrap; 
    flex-wrap: wrap;
  }
  
  .product-category,
  .product-price {
    margin: 0;
    font-weight: 400;
  }
  
  .sr-product-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 250px
  }

  .sr-product-info h3, p {
    font-family: 'Roboto', sans-serif;
    
  }

 
  .search-results-header {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    /* padding: 20px; */
    margin-left: 20px;
    margin-right: 20px;
  }

  .sr-price {
    display: flex;
    flex-direction: row;
  }

  @media (max-width: 767px) {
    .search-result-index-item  {
      max-width: 175px; /* Set a fixed width for each item */
      height: 100%; /* Set a fixed height for each item */
    }

    .search-result-index-item h3 {
      font-size: 16px;
      /* white-space: nowrap; */
      white-space: normal;
      padding-bottom: 10px;
    }

    .sr-product-info{
      width: 80% !important;
    }

  }